@import '../../styles/colors';
@import '../../styles/layers';

.h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em 0;

  &:before,
  &:after {
    content: '';
    height: 1px;
    background: $gray-800;
    flex: 0 1 100px;
    margin: 0 15px;
  }

  h2 {
    background: white;
    display: inline;
    position: relative;
    z-index: $layer-h2;
    padding: 0 8px;
    margin: 0;
    white-space: nowrap;
  }
}
