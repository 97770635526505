@import '../../styles/colors';
@import '../../styles/sizes';
@import '../../styles/layers';

.header {
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $layer-header;
  background: $color-dark-background;
  position: relative;
}

.header__logo {
  height: 60px;
}

.header__social-links {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: $header-height;
  display: flex;
  justify-content: center;
  align-items: center;
}
