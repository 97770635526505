// Color system
// ------------
//
// Name color variables according to this scheme:
//
// $color-900 <- a very light color
// $color-800
// $color-700
// $color-600
// $color-500
// $color-400
// $color-300
// $color-200
// $color-100
// $color-000 <- a very dark color

$navy-100: #12092d;

$blue-900: #dff5ff;
$blue-700: lighten(#228ae6, 20%);
$blue-500: #228ae6;
$blue-400: darken(#228ae6, 10%);

$gray-800: hsl(0, 0%, 80%);
$gray-600: hsl(0, 0%, 60%);
$gray-400: hsl(0, 0%, 40%);
$gray-300: hsl(0, 0%, 30%);
$gray-200: hsl(0, 0%, 20%);

// Color roles
// -----------
//
// When colors have meaning in the system,
// give the meaning a name
$color-text: $gray-300;
$color-text-reverse: white;
$color-light-background: white;
$color-dark-background: $navy-100;
$color-link: $blue-500;
$color-link-hover: $blue-400;
