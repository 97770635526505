@import '../../styles/colors';
@import '../../styles/layers';
@import '../../styles/breakpoints';
@import '../../styles/typography';

$slide-height: 530px;
$image-height: $slide-height * 0.22;

$slide-height-tablet: 480px;
$image-height-tablet: $slide-height-tablet * 0.35;

$slide-height-desktop: 625px;
$image-height-desktop: $slide-height-desktop * 0.35;

.slick-slider {
  margin-bottom: 50px;

  .slick-slide {
    outline: 0;

    div {
      outline: 0;
    }
  }

  .slick-arrow {
    $size: 62px;
    $padding: 12px;
    position: absolute;
    z-index: 100;
    top: calc(50% - #{$size / 2});
    right: $padding;
    background-color: transparent;
    background-image: url(../../images/slider-next.svg);
    background-size: $size $size;
    font-size: 0;
    line-height: 0;
    color: transparent;
    width: $size;
    height: $size;
    border: 0;
    outline: 0;
    cursor: pointer;

    &:hover {
      background-image: url(../../images/slider-next-hover.svg);
    }

    &.slick-prev {
      transform: rotate(180deg);
      right: auto;
      left: $padding;
    }
  }
}

#arrow-preloader {
  background: url(../../images/slider-next-hover.svg) no-repeat -9999px -9999px;
}

.bw-slide {
  background: white;
  box-shadow: 0 0 16px 4px rgba(black, 0.15);
  border-radius: 10px;
  margin: 16px 10px;
  height: $slide-height;
  overflow: hidden;

  @include bp(tablet) {
    height: $slide-height-tablet;
  }

  @include bp(desktop) {
    height: $slide-height-desktop;
  }

  &__image {
    width: 100%;
    height: $image-height;
    background-size: cover;
    background-position: center;

    @include bp(tablet) {
      height: $image-height-tablet;
    }

    @include bp(desktop) {
      height: $image-height-desktop;
    }
  }

  &__content {
    $margin-mobile: 15px;
    margin: $margin-mobile;
    height: calc(100% - #{$image-height + ($margin-mobile * 2)});

    &--no-image {
      margin: $margin-mobile;
      height: calc(100% - #{$margin-mobile * 2});
    }

    @include bp(tablet) {
      height: calc(100% - #{$image-height-tablet + ($margin-mobile * 2)});
    }

    @include bp(desktop) {
      $margin: 20px;
      margin: $margin;
      height: calc(100% - #{$image-height-desktop + ($margin * 2)});

      &--no-image {
        margin: $margin;
        height: calc(100% - #{$margin * 2});
      }
    }
  }

  &__scrollable {
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    padding-bottom: 14px;
    line-height: 24px;

    @include bp(tablet) {
      line-height: 28px;
    }
  }

  &__donations {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__donation {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    line-height: 1.2em;
    color: $navy-100;

    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  &__donation-value {
    color: red;
  }

  h3 {
    font-size: 20px;
    color: $navy-100;
    font-weight: bold;
    margin: 0;

    @include bp(tablet) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 28px;
    color: $navy-100;
    line-height: 34px;
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 1.5em;
    color: $color-text;

    @include bp(xlarge) {
      font-size: 15px;
    }
  }

  &.buffy-wicks {
    box-shadow: 0 0 16px 6px rgba(black, 0.35);

    .bw-slide__name {
      display: block;
      text-align: center;
    }

    h3 {
      font-size: 30px;
      line-height: normal;

      @include bp(tablet) {
        font-size: 35px;
      }
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px;
  }

  &__category {
    @include lato;
    background: $gray-600;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 13px;
    color: white;
    margin: 5px 5px 0 0;
  }
}

.filters-wrapper {
  margin: 10px 0 15px;
}

.filter-wrapper {
  padding: 15px 20px 0;
  max-width: 500px;
  margin: 0 auto;
}
