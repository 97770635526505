@import '../../styles/colors';

.button-wrapper {
  display: flex;
  justify-content: center;
}

.button {
  display: inline-block;
  padding: 14px 42px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.1s;
  text-decoration: none;

  &.blue {
    background-color: $blue-500;
    color: white;

    &:hover {
      background-color: $blue-400;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px $blue-700;
    }
  }

  &.space {
    margin-top: 35px;
  }
}
