@import './styles/breakpoints';
@import './styles/colors';

.content {
  margin: 26px 0px 62px;
}

.content {
  .inner {
    margin: 0 auto;
    max-width: 660px;
    padding: 0px 25px;
  }

  &.dark {
    background-color: $color-dark-background;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: white;
    }

    .inner {
      padding: 22px 42px;
    }
  }
}
