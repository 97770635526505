@import '../../styles/colors';

.faq-wrapper {
  .ReactCollapse--content {
    padding-bottom: 1.5em;
  }

  .h2 {
    margin: 0;
  }

  h3 {
    padding: 1.5em 0;
    margin: 0;
    width: 100%;
    padding-right: 40px;
    position: relative;
    cursor: pointer;

    &::after {
      content: '\2039';
      display: block;
      color: $navy-100;
      font-weight: normal;
      font-size: 40px;
      line-height: 32px;
      height: 40px;
      width: 40px;
      text-align: center;
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: transform 0.3s;
    }

    &.opened::after {
      transform: translateY(-50%) rotate(90deg);
    }

    &:hover,
    &:hover::after {
      color: $color-link;
    }
  }

  p {
    padding: 0.5em 0;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
