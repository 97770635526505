/*
Breakpoints
-----------

Use it like this:

.selector {
  @include bp(tablet) {
    color: $blue-light;
  }
}
*/

$breakpoints: (
  tablet: 768px,
  desktop: 1000px,
  xlarge: 1200px
);

@mixin bp($size) {
  @media (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}
