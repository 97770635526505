@import './colors';

@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i');

@mixin lato {
  font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
input,
button,
a,
.react-select-container {
  @include lato;
}

h2 {
  font-weight: bold;
  font-size: 20px;
  color: $navy-100;
}

h3 {
  line-height: 1.4em;
  color: $navy-100;
}

p,
li {
  font-size: 16px;
  color: $color-text;
  line-height: 1.5em;
}

a {
  color: $color-link;

  &:hover {
    color: $color-link-hover;
  }
}
