@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i);
.h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em 0; }
  .h2:before, .h2:after {
    content: '';
    height: 1px;
    background: #cccccc;
    flex: 0 1 100px;
    margin: 0 15px; }
  .h2 h2 {
    background: white;
    display: inline;
    position: relative;
    z-index: 1;
    padding: 0 8px;
    margin: 0;
    white-space: nowrap; }

/*
Breakpoints
-----------

Use it like this:

.selector {
  @include bp(tablet) {
    color: $blue-light;
  }
}
*/
.intro {
  text-align: center;
  margin: 25px 0 0; }
  .intro .h2 {
    margin-bottom: 1.2em; }
  .intro .donation-value {
    font-size: 28px;
    margin: 0;
    color: red;
    font-weight: bold; }
  .intro .donation-subhead {
    margin: 0;
    font-size: 13px; }
  .intro .introduction {
    margin: 0 auto 25px;
    padding: 30px 35px 0;
    max-width: 660px; }

/*
Breakpoints
-----------

Use it like this:

.selector {
  @include bp(tablet) {
    color: $blue-light;
  }
}
*/
.social-links {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: flex-end;
  flex: 1 1 auto;
  margin: 0 20px;
  padding: 0; }

.social-links__link {
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin: 0;
  transition: opacity 0.1s; }
  .social-links__link:hover {
    opacity: 0.8; }
  .social-links__link:focus {
    outline: none;
    box-shadow: 0 0 0 4px #7ebbf0;
    border-radius: 5px; }
  @media (min-width: 768px) {
    .social-links__link {
      justify-content: flex-start;
      margin: 0 10px; } }

.header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 201;
  background: #12092d;
  position: relative; }

.header__logo {
  height: 60px; }

.header__social-links {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center; }

/*
Breakpoints
-----------

Use it like this:

.selector {
  @include bp(tablet) {
    color: $blue-light;
  }
}
*/
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
input,
button,
a,
.react-select-container {
  font-family: 'Lato', sans-serif; }

h2 {
  font-weight: bold;
  font-size: 20px;
  color: #12092d; }

h3 {
  line-height: 1.4em;
  color: #12092d; }

p,
li {
  font-size: 16px;
  color: #4d4d4d;
  line-height: 1.5em; }

a {
  color: #228ae6; }
  a:hover {
    color: #1670bf; }

.slick-slider {
  margin-bottom: 50px; }
  .slick-slider .slick-slide {
    outline: 0; }
    .slick-slider .slick-slide div {
      outline: 0; }
  .slick-slider .slick-arrow {
    position: absolute;
    z-index: 100;
    top: calc(50% - 31px);
    right: 12px;
    background-color: transparent;
    background-image: url(/static/media/slider-next.0c94328f.svg);
    background-size: 62px 62px;
    font-size: 0;
    line-height: 0;
    color: transparent;
    width: 62px;
    height: 62px;
    border: 0;
    outline: 0;
    cursor: pointer; }
    .slick-slider .slick-arrow:hover {
      background-image: url(/static/media/slider-next-hover.66952401.svg); }
    .slick-slider .slick-arrow.slick-prev {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      right: auto;
      left: 12px; }

#arrow-preloader {
  background: url(/static/media/slider-next-hover.66952401.svg) no-repeat -9999px -9999px; }

.bw-slide {
  background: white;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 16px 10px;
  height: 530px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .bw-slide {
      height: 480px; } }
  @media (min-width: 1000px) {
    .bw-slide {
      height: 625px; } }
  .bw-slide__image {
    width: 100%;
    height: 116.6px;
    background-size: cover;
    background-position: center; }
    @media (min-width: 768px) {
      .bw-slide__image {
        height: 168px; } }
    @media (min-width: 1000px) {
      .bw-slide__image {
        height: 218.75px; } }
  .bw-slide__content {
    margin: 15px;
    height: calc(100% - 146.6px); }
    .bw-slide__content--no-image {
      margin: 15px;
      height: calc(100% - 30px); }
    @media (min-width: 768px) {
      .bw-slide__content {
        height: calc(100% - 198px); } }
    @media (min-width: 1000px) {
      .bw-slide__content {
        margin: 20px;
        height: calc(100% - 258.75px); }
        .bw-slide__content--no-image {
          margin: 20px;
          height: calc(100% - 40px); } }
  .bw-slide__scrollable {
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; }
    .bw-slide__scrollable p:first-child {
      margin-top: 0; }
    .bw-slide__scrollable p:last-child {
      margin-bottom: 0; }
  .bw-slide__name {
    padding-bottom: 14px;
    line-height: 24px; }
    @media (min-width: 768px) {
      .bw-slide__name {
        line-height: 28px; } }
  .bw-slide__donations {
    display: flex;
    justify-content: space-between;
    margin-top: 5px; }
  .bw-slide__donation {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    line-height: 1.2em;
    color: #12092d; }
    .bw-slide__donation:not(:first-child) {
      margin-left: 5px; }
  .bw-slide__donation-value {
    color: red; }
  .bw-slide h3 {
    font-size: 20px;
    color: #12092d;
    font-weight: bold;
    margin: 0; }
    @media (min-width: 768px) {
      .bw-slide h3 {
        font-size: 24px; } }
  .bw-slide h4 {
    font-size: 28px;
    color: #12092d;
    line-height: 34px;
    font-weight: bold;
    margin: 0; }
  .bw-slide p {
    font-size: 14px;
    line-height: 1.5em;
    color: #4d4d4d; }
    @media (min-width: 1200px) {
      .bw-slide p {
        font-size: 15px; } }
  .bw-slide.buffy-wicks {
    box-shadow: 0 0 16px 6px rgba(0, 0, 0, 0.35); }
    .bw-slide.buffy-wicks .bw-slide__name {
      display: block;
      text-align: center; }
    .bw-slide.buffy-wicks h3 {
      font-size: 30px;
      line-height: normal; }
      @media (min-width: 768px) {
        .bw-slide.buffy-wicks h3 {
          font-size: 35px; } }
  .bw-slide__categories {
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px; }
  .bw-slide__category {
    font-family: 'Lato', sans-serif;
    background: #999999;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 13px;
    color: white;
    margin: 5px 5px 0 0; }

.filters-wrapper {
  margin: 10px 0 15px; }

.filter-wrapper {
  padding: 15px 20px 0;
  max-width: 500px;
  margin: 0 auto; }

.footer {
  min-height: 0 !important;
  margin-bottom: 0 !important;
  padding: 30px 0;
  display: flex;
  flex-direction: column; }
  .footer .ebdsa-logo {
    width: 100px;
    height: 100px;
    background-image: url(/static/media/ebdsa-logo.8c52cbcf.svg);
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
    margin-bottom: 20px; }
  .footer a {
    color: white;
    text-decoration: none; }
    .footer a:hover {
      color: white;
      text-decoration: underline; }

.faq-wrapper .ReactCollapse--content {
  padding-bottom: 1.5em; }

.faq-wrapper .h2 {
  margin: 0; }

.faq-wrapper h3 {
  padding: 1.5em 0;
  margin: 0;
  width: 100%;
  padding-right: 40px;
  position: relative;
  cursor: pointer; }
  .faq-wrapper h3::after {
    content: '\2039';
    display: block;
    color: #12092d;
    font-weight: normal;
    font-size: 40px;
    line-height: 32px;
    height: 40px;
    width: 40px;
    text-align: center;
    position: absolute;
    right: -11px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-90deg);
            transform: translateY(-50%) rotate(-90deg);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .faq-wrapper h3.opened::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg); }
  .faq-wrapper h3:hover, .faq-wrapper h3:hover::after {
    color: #228ae6; }

.faq-wrapper p {
  padding: 0.5em 0;
  margin: 0; }
  .faq-wrapper p:first-child {
    padding-top: 0; }
  .faq-wrapper p:last-child {
    padding-bottom: 0; }

.button-wrapper {
  display: flex;
  justify-content: center; }

.button {
  display: inline-block;
  padding: 14px 42px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.1s;
  text-decoration: none; }
  .button.blue {
    background-color: #228ae6;
    color: white; }
    .button.blue:hover {
      background-color: #1670bf; }
    .button.blue:focus {
      outline: none;
      box-shadow: 0 0 0 4px #7ebbf0; }
  .button.space {
    margin-top: 35px; }

/*
Breakpoints
-----------

Use it like this:

.selector {
  @include bp(tablet) {
    color: $blue-light;
  }
}
*/
.content {
  margin: 26px 0px 62px; }

.content .inner {
  margin: 0 auto;
  max-width: 660px;
  padding: 0px 25px; }

.content.dark {
  background-color: #12092d;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .content.dark p {
    color: white; }
  .content.dark .inner {
    padding: 22px 42px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
input,
button,
a,
.react-select-container {
  font-family: 'Lato', sans-serif; }

h2 {
  font-weight: bold;
  font-size: 20px;
  color: #12092d; }

h3 {
  line-height: 1.4em;
  color: #12092d; }

p,
li {
  font-size: 16px;
  color: #4d4d4d;
  line-height: 1.5em; }

a {
  color: #228ae6; }
  a:hover {
    color: #1670bf; }

