.footer {
  min-height: 0 !important; // TODO
  margin-bottom: 0 !important; // TODO
  padding: 30px 0;
  display: flex;
  flex-direction: column;

  .ebdsa-logo {
    $size: 100px;
    width: $size;
    height: $size;
    background-image: url(../../images/ebdsa-logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
    margin-bottom: 20px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
